import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import typesetter from '@haaretz/l-type.macro';
import * as React from 'react';
import s9 from 'style9';

import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    textAlign: 'center',
    ...merge(
      mq({
        until: 'xl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(-3),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-4),
        },
      })
    ),
  },
  visible: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
});

export interface AdCaptionProps {
  caption: string;
  isVisible?: boolean;
  styleExtend?: StyleExtend;
}

export default function AdCaption({
  caption,
  isVisible = false,
  styleExtend = [],
}: AdCaptionProps) {
  return (
    <div
      data-testid="adslot-caption"
      className={s9(c.base, (isVisible && c.visible) || c.hidden, ...styleExtend)}
    >{`- ${caption} -`}</div>
  );
}
