'use client';
import React from 'react';

import { playbackRate } from '../utils/audioPlayerUtils';

const noop = () => undefined;

export type AudioPlayerContextType = {
  audio: null | HTMLAudioElement;
  title: string;
  channelName?: string | null;
  currentTime: number;
  durationTime: number;
  isPlaying: boolean;
  wasNotPlayed: boolean;
  playbackIndex: number;

  onSkip: (seconds: number) => void;
  onPlayButtonClick: () => void;
  onPlayingCallback: (callback: () => void) => void;
  onPauseCallback: (callback: () => void) => void;
  onPauseButtonClick: () => void;
  onNextPlayBackRate: () => void;
};

const defaultValue = {
  audio: null,
  title: '',
  channelName: null,
  currentTime: 0,
  durationTime: 0,
  isPlaying: false,
  wasNotPlayed: true,
  playbackIndex: playbackRate[0],

  onSkip: noop,
  onPlayingCallback: noop,
  onPauseCallback: noop,
  onPlayButtonClick: noop,
  onPauseButtonClick: noop,
  onNextPlayBackRate: noop,
};

const AudioPlayerContext = React.createContext<AudioPlayerContextType>(defaultValue);
export function useAudioPlayer() {
  return React.useContext(AudioPlayerContext);
}

export default AudioPlayerContext;
