'use client';

import React from 'react';

import { tranlateFluidSize } from '../utils';

import type { AdSlotEventHandlers } from '../AdSlot/AdSlot';
import type { AdSlotFragment } from '@haaretz/s-fragments/AdSlot';

export const OutOfPageFormat: typeof googletag.enums.OutOfPageFormat = {
  BOTTOM_ANCHOR: 3, // Anchor format where slot sticks to the bottom of the viewport.
  // GAME_MANUAL_INTERSTITIAL: 7,
  INTERSTITIAL: 5, // Web interstitial creative format.
  LEFT_SIDE_RAIL: 8,
  REWARDED: 4, // Rewarded format.
  RIGHT_SIDE_RAIL: 9,
  TOP_ANCHOR: 2, // Anchor format where slot sticks to the top of the viewport.
} as const;

const HALF_MINUTE = 30000; // millis

export interface OutOfPageAdSlotProps
  extends AdSlotEventHandlers,
    Omit<AdSlotFragment, 'inlineStyle' | 'interstitial' | 'divId'> {
  divId: googletag.enums.OutOfPageFormat | AdSlotFragment['divId'];
  targeting?: { [key: string]: string | string[] };
}

type OutOfPageAdSlotState = 'loaded' | 'failed' | 'init';

/**
 * Writes to the local-storage the time when slot was rendered to page (googletag.display() was called)
 * and when it was actually viewed on page ('slotOnload' event was triggered).
 *
 * The 'GAM_OutOfPage' key is used for a anoncing **other banners on the page** that an OutOfPage slot was rendered.
 *
 * @returns the time in millis
 */
function setOutOfPageSlotRenderTime(state: OutOfPageAdSlotState) {
  let now = Date.now();

  if (state === 'failed') {
    // HACK: We need to reduce the time so that when going to the next page othet slot will be shown
    now -= HALF_MINUTE;
  }

  localStorage && localStorage.setItem('GAM_OutOfPage', now.toString());

  return now;
}

/**
 * Writes to the local-storage the time when slot was actualy viewed on page ('slotOnload' event was triggered).
 *
 * The 'GAM_<contentId>' key is used by the out-of-page banner to test when was the last time it actually viewed
 *
 * @param contentId - id of the ad-slot
 * @returns the time in millis
 */
function setOutOfPageSlotViewTime(contentId: string, state: OutOfPageAdSlotState) {
  const adSlotKey = `GAM_${contentId}`;
  const time = setOutOfPageSlotRenderTime(state);
  localStorage && localStorage.setItem(adSlotKey, time.toString());

  return time;
}

export default function OutOfPageAdSlot({
  contentId,
  adUnitPath,
  divId,
  sizeMapping,
  targeting,
  slotOnLoad,
  slotImpressionViewable,
}: OutOfPageAdSlotProps) {
  // The managed-ads formats automatically creates and inserts its own container into the page.
  const isManagedOutOfPageAd = React.useMemo(
    () => typeof divId !== 'string' && divId && Object.values(OutOfPageFormat).includes(divId),
    [divId]
  );
  const [isSupported, setIsSupported] = React.useState(false);
  const slotRef = React.useRef<googletag.Slot>();
  const outOfPageAdSlotState = React.useRef<OutOfPageAdSlotState>('init');

  const slotOnLoadEventHandler = React.useMemo(() => {
    if (!slotOnLoad) {
      return null;
    }

    return (event: googletag.events.SlotOnloadEvent) => {
      if (slotRef.current === event.slot) {
        outOfPageAdSlotState.current = 'loaded';
        slotOnLoad(slotRef.current);
      }
    };
  }, [slotOnLoad]);

  const impressionViewableEventHandler = React.useCallback(
    (event: googletag.events.ImpressionViewableEvent) => {
      if (slotRef.current === event.slot) {
        slotImpressionViewable && slotImpressionViewable(slotRef.current);

        setOutOfPageSlotViewTime(contentId, outOfPageAdSlotState.current);
        setOutOfPageSlotRenderTime(outOfPageAdSlotState.current);
      }
    },
    [contentId, slotImpressionViewable]
  );

  const slotRenderEndedEventHandler = React.useCallback(
    (event: googletag.events.ImpressionViewableEvent) => {
      if (outOfPageAdSlotState.current !== 'loaded' && slotRef.current === event.slot) {
        outOfPageAdSlotState.current = 'failed';

        slotImpressionViewable && slotImpressionViewable(slotRef.current);

        setOutOfPageSlotViewTime(contentId, outOfPageAdSlotState.current);
        setOutOfPageSlotRenderTime(outOfPageAdSlotState.current);
      }
    },
    [contentId, slotImpressionViewable]
  );

  React.useEffect(() => {
    if (!adUnitPath) {
      return undefined;
    }

    let adSizeMapping: googletag.SizeMappingArray | null;
    let slot: googletag.Slot | null;

    // Google AD init
    googletag.cmd.push(() => {
      slot = googletag.defineOutOfPageSlot(adUnitPath, divId ?? '');
      slotRef.current = slot ?? undefined;

      // Slot returns null if the page or device does not support interstitials.
      if (!slot) {
        return;
      }

      if (sizeMapping && sizeMapping.length > 0) {
        const adSizeMappingBuilder = googletag.sizeMapping();

        sizeMapping.forEach(mapping => {
          adSizeMappingBuilder.addSize(
            mapping.viewport as googletag.SingleSizeArray,
            mapping.sizes.map(tranlateFluidSize)
          );
        });

        adSizeMapping = adSizeMappingBuilder.build();
        if (adSizeMapping) {
          slot.defineSizeMapping(adSizeMapping);
        }
      }

      // Slot is not null and can be displayed.
      setIsSupported(true);

      slot.addService(googletag.pubads());

      if (targeting) {
        slot.updateTargetingFromMap(targeting);
      }

      if (slotOnLoadEventHandler) {
        googletag.pubads().addEventListener('slotOnload', slotOnLoadEventHandler);
      }

      if (impressionViewableEventHandler) {
        googletag.pubads().addEventListener('impressionViewable', impressionViewableEventHandler);
      }

      if (slotRenderEndedEventHandler) {
        googletag.pubads().addEventListener('slotRenderEnded', slotRenderEndedEventHandler);
      }

      googletag.display(slot);

      // Some banners should not be displayed if an OutOfPage banner (such as interstitial) is displayed.
      // This is how we let them know
      setOutOfPageSlotRenderTime('init');
    });

    // Destroy slot on un-mount
    return () => {
      if (slot) {
        if (slotOnLoadEventHandler) {
          googletag.pubads().removeEventListener('slotOnload', slotOnLoadEventHandler);
        }

        if (impressionViewableEventHandler) {
          googletag
            .pubads()
            .removeEventListener('impressionViewable', impressionViewableEventHandler);
        }

        googletag.destroySlots([slot]);
      }
    };
  }, [
    adUnitPath,
    contentId,
    divId,
    impressionViewableEventHandler,
    sizeMapping,
    slotOnLoadEventHandler,
    slotRenderEndedEventHandler,
    targeting,
  ]);

  return isSupported && !isManagedOutOfPageAd ? (
    <div
      data-testid="out-of-page-slot"
      id={`${divId}`}
      data-adunit={adUnitPath}
      data-cid={contentId}
      suppressHydrationWarning
    />
  ) : null;
}
