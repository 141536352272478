/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import { relative } from 'path';

import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

import type { AudioPlayerContextType } from '../hooks/useAudioPlayer';
import type { StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  base: {
    width: '100%',
    paddingTop: space(5),
    paddingLeft: space(4),
    paddingRight: space(4),
    paddingBottom: space(5),
    ...merge(
      mq({
        from: 'm',
        value: {
          paddingTop: space(6),
          paddingLeft: space(5),
          paddingRight: space(5),
          paddingBottom: space(7),
        },
      }),
      mq({
        from: 'l',
        value: {
          padding: space(6),
        },
      })
    ),
  },
  channelName: {
    color: color('primary1000'),
    fontWeight: fork({ default: 400, hdc: 700 }),
    ...typesetter(-1),
    ...merge(
      mq({
        from: 'm',
        value: {
          ...typesetter(0),
        },
      }),
      mq({
        from: 'xl',
        value: {
          ...typesetter(-1),
        },
      })
    ),
  },
  channelNameList: {
    fontWeight: 700,
    marginBottom: space(1),
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...typesetter(0),
    ...merge(
      mq({
        from: 'm',
        value: {
          ...typesetter(1),
        },
      }),
      mq({
        from: 'l',
        value: {
          ...typesetter(2),
        },
      }),
      mq({
        from: 'xl',
        value: {
          ...typesetter(1),
        },
      })
    ),
  },
  channelNamePrefix: {
    fontWeight: 700,
    marginInlineEnd: space(1),
  },
});

type FirstPlayProps = {
  channelName: AudioPlayerContextType['channelName'];
  title: AudioPlayerContextType['title'];
  children?: React.ReactNode;
  styleExtend?: StyleExtend;
  variant?: 'default' | 'schoonhoven';
};

export default function FirstPlay({
  channelName,
  title,
  styleExtend = [],
  children,
  variant = 'default',
}: FirstPlayProps) {
  return (
    <div className={s9(c.base, ...styleExtend)}>
      <div className={s9(c.channelName, variant === 'schoonhoven' && c.channelNameList)}>
        {variant === 'default' ? (
          fork({
            default: (
              <>
                <span className={s9(c.channelNamePrefix)}>האזינו לפודקאסט:</span>
                {channelName}
              </>
            ),
            hdc: <>{channelName}</>,
          })
        ) : (
          <>{channelName}</>
        )}
      </div>
      <div className={s9(c.main)}>
        <span>{title}</span>
        {children}
      </div>
    </div>
  );
}
